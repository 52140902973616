import { Droppable } from "react-beautiful-dnd";
import DraggablePlayerTile from "./DraggablePlayerTile";
import classes from "./PlayerGrid.module.css";

const DroppableGridCell = ({
    rowIndex,
    position,
    player,
    updatesAllowed,
    stackView,
    onTileMoved,
    ...tileProps
}) => {
    return (
        <Droppable
            droppableId={`${rowIndex + 1}-${position}`}
            isDropDisabled={!updatesAllowed}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`${
                        classes.playergrid_cell +
                        (stackView ? " " + classes.stack_view : "") +
                        (onTileMoved ? " " + classes.dnd_layout : "")
                    } ${player ? "" : classes.empty_cell} droppable ${
                        snapshot.isDraggingOver
                            ? classes.droppable_isDraggingOver
                            : ""
                    }`}
                >
                    {player && (
                        <DraggablePlayerTile
                            player={player}
                            row={rowIndex}
                            position={position}
                            index={0}
                            updatesAllowed={updatesAllowed}
                            {...tileProps}
                        />
                    )}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default DroppableGridCell;
