import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { usersActions } from "./users-slice";

let ongoingRequests = {
    fetchCoaches: null, // This will be either null or a Promise
};

export const fetchCoaches = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchCoaches) {
            ongoingRequests.fetchCoaches = fetchData(
                `${process.env.REACT_APP_HABSHUB_API_BASE_URL}users/?group=Player Development Staff&group=Coaching Staff`,
                "Could not fetch coaches data!"
            )
                .then((coachesData) => {
                    dispatch(usersActions.updateCoaches(coachesData));
                    ongoingRequests.fetchCoaches = null;
                    return coachesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching coaches data failed!",
                        })
                    );
                    ongoingRequests.fetchCoaches = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchCoaches;
    };
};
