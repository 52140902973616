import { useState, useEffect, useMemo } from "react";
import { player_layout_positions } from "../../constants";
import { DragDropContext } from "react-beautiful-dnd";
import classes from "./PlayerGrid.module.css";
import { PlayerTileWidthLayouts } from "../playertile/Playertile";
import { PlayerTileModes } from "../../store/ui-slice";
import useWindowDimensions from "../../hooks/use-window-dimensions";
import PlayerGridRow from "./PlayerGridRow";

const PlayerGrid = ({
    grid_content,
    updatesAllowed = false,
    onTileMoved,
    onTileClicked,
    onInsertRow,
    onRemoveRow,
    tileView = PlayerTileModes.PERFORMANCE,
    tightLayout = false,
    stackView = false,
    showProjectedFreeAgencyStatus = false,
    showDraftTeamOverlay = false,
    showOwningTeamOverlay = false,
    alternateColumnLabels = null,
    rowCountersGroupSize = null,
}) => {
    const { widthClasses } = useWindowDimensions([
        { min: 1430, max: Infinity },
        { min: 1375, max: 1430 },
        { min: 0, max: 1170 },
    ]);

    const [tileWidthLayout, setTileWidthLayout] = useState(PlayerTileWidthLayouts.STANDARD);

    useEffect(() => {
        setTileWidthLayout(
            widthClasses[0]?.matches
                ? PlayerTileWidthLayouts.WIDE
                : widthClasses[1]?.matches
                ? PlayerTileWidthLayouts.STANDARD_STRONG_OVERLAY
                : widthClasses[2]?.matches
                ? PlayerTileWidthLayouts.NARROW
                : PlayerTileWidthLayouts.STANDARD
        );
    }, [widthClasses]);

    const handleDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const [src_row, src_position] = source.droppableId.split("-");
        const [dest_row, dest_position] = destination.droppableId.split("-");
        const drag_player_id = draggableId.split("-")[2];

        onTileMoved(
            drag_player_id,
            parseInt(dest_row),
            dest_position,
            parseInt(src_row),
            src_position
        );
    };

    const row_count_labels = useMemo(() => {
        if (rowCountersGroupSize) {
            let prevCount = 0;
            let currentCount = 0;

            return grid_content.reduce((acc, row, idx) => {
                prevCount += currentCount;
                currentCount = Object.values(row).filter((v) => v).length;
                if (
                    Math.floor(prevCount / rowCountersGroupSize) !==
                        Math.floor(
                            (prevCount + currentCount) / rowCountersGroupSize
                        ) ||
                    idx === grid_content.length - 1
                ) {
                    acc[idx] = prevCount + currentCount;
                }
                return acc;
            }, {});
        }
        return null;
    }, [grid_content, rowCountersGroupSize]);

    return (
        <div className={`${classes.playergrid} ${tightLayout ? classes.tight_layout : ''}`}>
            <div className={`${classes.playergrid_row} ${classes.playergrid_header}`}>
                {player_layout_positions.map((position, idx) => (
                    <div className={classes.playergrid_cell} key={position}>
                        {(!stackView || grid_content.some((row) => row[position])) && (
                            <div className={classes.position_label}>
                                {alternateColumnLabels ? alternateColumnLabels[idx] : position}
                            </div>
                        )}
                    </div>
                ))}
                <div className={`${classes.row_count_container} ${rowCountersGroupSize ? '' : classes.no_count}`}>
                    <div className={classes.row_count}></div>
                </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                {grid_content.map((row, rowIndex) => (
                    <PlayerGridRow
                        key={rowIndex}
                        rowIndex={rowIndex}
                        row={row}
                        player_layout_positions={player_layout_positions}
                        updatesAllowed={updatesAllowed}
                        onInsertRow={onInsertRow}
                        onRemoveRow={onRemoveRow}
                        rowCountersGroupSize={rowCountersGroupSize}
                        row_count_labels={row_count_labels}
                        tileView={tileView}
                        onTileClicked={onTileClicked}
                        showDraftTeamOverlay={showDraftTeamOverlay}
                        showOwningTeamOverlay={showOwningTeamOverlay}
                        showProjectedFreeAgencyStatus={showProjectedFreeAgencyStatus}
                        tileWidthLayout={tileWidthLayout}
                        stackView={stackView}
                        onTileMoved={onTileMoved}
                    />
                ))}
            </DragDropContext>
        </div>
    );
};

export default PlayerGrid;