import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchData } from "../utils";
import { uiActions } from "../store/ui-slice";

export const useTextToneDetection = () => {
    const dispatch = useDispatch();

    const detectTextTone = useCallback(
        async (text) => {
            const postData = {
                text: text,
            };

            try {
                const response = await fetchData(
                    `${process.env.REACT_APP_HABSHUB_API_BASE_URL}hockey_analytics/toneanalysis/`,
                    "Failed to detect text tone!",
                    "POST",
                    postData
                );

                return response.tone;
            } catch (error) {
                dispatch(
                    uiActions.showNotification({
                        status: "error",
                        title: "Error!",
                        message: "Detecting text tone failed!",
                    })
                );
                throw error;
            }
        },
        [dispatch]
    );

    return { detectTextTone };
};
