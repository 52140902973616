import { Draggable } from "react-beautiful-dnd";
import Playertile from "../playertile/Playertile";
import classes from "./PlayerGrid.module.css";

const DraggablePlayerTile = ({
    player,
    row,
    position,
    index,
    updatesAllowed,
    tileView,
    onTileClicked,
    showDraftTeamOverlay,
    showOwningTeamOverlay,
    showProjectedFreeAgencyStatus,
    tileWidthLayout,
}) => {
    return (
        <Draggable
            draggableId={`${row}-${position}-${player.id.toString()}`}
            index={index}
            isDragDisabled={!updatesAllowed}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`${classes.playertile_container} ${
                        updatesAllowed && classes.playertile_draggable
                    } draggable ${
                        snapshot.isDragging ? classes.draggable_isDragging : ""
                    }`}
                >
                    <Playertile
                        player={player}
                        namedecoration={player.nameDecoration}
                        namedecorationclass={player.nameDecorationClass}
                        tileView={tileView}
                        onClick={onTileClicked}
                        overlayDraftedTeam={showDraftTeamOverlay}
                        overlayOwningTeam={showOwningTeamOverlay}
                        showProjectedFreeAgencyStatus={
                            showProjectedFreeAgencyStatus
                        }
                        widthLayout={tileWidthLayout}
                    />
                </div>
            )}
        </Draggable>
    );
};

export default DraggablePlayerTile;
